<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">

      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>hébergement</h1>

        <h2 class="small-title">Validité</h2>
        <p class="regular-text">Le rabais sur les séjours de 2 nuits s’applique ainsi :</p>
        <ul>
          <li class="regular-text">15 % du 17 décembre 2021 au 19 mars 2022.</li>
          <li class="regular-text">20 % du 1er novembre au 16 décembre 2021 et du 20 mars au 30 avril 2022.</li>
          <li class="regular-text">Le rabais de 25 % s’applique sur les séjours de 3 nuits du 1er novembre 2021 au 30 avril 2022.</li>
          <li class="regular-text">Le rabais de 30 % s’applique sur les séjours de 4 nuits ou plus du 1er novembre 2021 au 30 avril 2022.</li>
          <li class="regular-text">Offre valable tous les jours, pour des séjours entre le 1er novembre 2021 et le 30 avril 2022, sauf les 10 et 11 décembre 2021, du 26 décembre 2021 au 1er janvier 2022, du 18 au 26 février 2022, du 27 février au 5 mars 2022 et du 11 au 19 mars 2022 où les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</li>
          <li class="regular-text">Offre disponible chez les partenaires hôteliers participants.</li>
          <li class="regular-text">Sujet à disponibilité.</li>
        </ul>
        
        <h2 class="small-title">Achat et réservation</h2>
        <p class="regular-text">L’offre s’applique sur toute réservation effectuée entre le 10 mai et le 19 octobre 2021, pour un séjour de 2 nuits ou plus entre le 1er novembre 2021 et le 30 avril 2022.</p>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Les politiques d’acompte et d’annulation régulières des partenaires hôteliers s’appliquent à cette offre.</li>
          <li class="regular-text"><a href="https://www.tremblant.ca/a-propos/politiques?sc_lang=fr#hebergement" target="_blank">Politiques de réservation et d’annulation assouplies</a> des partenaires hôteliers.  Les politiques varient d’un partenaire à l’autre.  Sujet à changement sans préavis.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Les 10 et 11 décembre 2021, du 26 décembre 2021 au 1er janvier 2022, du 18 au 26 février 2022, du 27 février au 5 mars 2022 et du 11 au 19 mars 2022, les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</li>
          <li class="regular-text">Cette offre ne peut être jumelée avec d’autres offres ou promotions sur l’hébergement.</li>
          <li class="regular-text">Si le séjour chevauche la période de début ou de fin de validité de l’offre, le rabais sera appliqué sur les nuits à l’intérieur de la période de validité selon le rabais correspondant au nombre total de nuits du séjour.</li>
          <li class="regular-text">Ces rabais et dates sont modifiables sans préavis.</li>
        </ul>

<!--         <h2 class="small-title">Planifiez et réservez à l'avance</h2>
        <ul>
          <li class="regular-text">Réservez d'avance et assurez-vous de profitez des meilleures disponibilités en hébergement avec nos politiques de réservation et d'annulation assouplies.</li>
        </ul> -->
      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Lodging<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <p class="regular-text">Discount on 2-night stays is as follows:</p>
        <ul>
          <li class="regular-text">15% discount from December 17, 2021, to March 19, 2022.</li>
          <li class="regular-text">20% discount from November 1 to December 16, 2021, and from March 20 to April 30, 2022.</li>
          <li class="regular-text">25% discount applies to 3-night stays from November 1, 2021, to April 30, 2022.</li>
          <li class="regular-text">30% discount applies to 4-night stays or more from November 1, 2021, to April 30, 2022.</li>
          <li class="regular-text">Valid every day from November 1, 2021, to April 30, 2022, except on December 10 and 11, from December 26, 2021, to January 1, 2022, from February 18 to 26, 2022, from February 27 to March 5, 2022, and from March 11 to 19, 2022, when hotels may offer lower discounts than the ones stipulated in this offer, or no discount at all.</li>
          <li class="regular-text">Offer available at participating hotels.</li>
          <li class="regular-text">Subject to availability.</li>
        </ul>
        
        <h2 class="small-title">Purchase and Booking</h2>
        <p class="regular-text">Offer applies to bookings made between May 10 and October 19, 2021, on 2-night stays or more from November 1, 2021, to April 30, 2022.</p>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Participant hotels' regular deposit and cancellation policies apply to this offer.</li>
          <li class="regular-text"><a href="https://www.tremblant.ca/about-us/policies?sc_lang=en#lodging" target="_blank">Flexible reservation and cancellation policies</a> of our hotel partners. Policies vary from partner to partner. Subject to change without notice.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">On December 10 and 11, from December 26, 2021, to January 1, 2022, from February 18 to 26, 2022, from February 27 to March 5, 2022, and from March 11 to 19, 2022, hotels may offer lower discounts than the ones stipulated in this offer, or no discount at all.</li>
          <li class="regular-text">This offer cannot be combined with any other offer or promotion on lodging.</li>
          <li class="regular-text">If stay period starts or ends outside of the validity period, discount will be applied on nights included within the validity period only and its value will vary according to the number of nights included in the entire stay.</li>
          <li class="regular-text">These discounts and dates are subject to change without notice.</li>
        </ul>

        <!-- <h2 class="small-title">Plan and book ahead</h2>
        <ul>
          <li class="regular-text">Book in advance and be sure to enjoy the best availabilities on accommodation with our flexible booking and cancellation policies.</li>
        </ul> -->
      </div>
      
    </section>

    <footer-bar></footer-bar>

    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
